import { useEffect } from "react";
import { storeUserLocationData } from "../utils/helperFunctions";

export default function useLoginDetailsSetter(userData) {
  const setUserDetails = () => {
    if (userData?.user_id) {
      localStorage.setItem("userId", userData?.user_id || "");
      localStorage.setItem("userLoginDetails", JSON.stringify({ data: userData }) || "");
    }
  };
  useEffect(() => {
    setUserDetails();
    storeUserLocationData();
  }, []);
}
