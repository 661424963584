import { prelemBaseEndpointObj } from "../helperFunctions";
import { getdefaultEventObj, getPipedData, prepareObjectForRPI } from "./helper";
import { trackImpression } from "./rpiImpression";
import {
  BUTTON_CLICK,
  CONTENT_STATUS,
  IMPRESSION,
  MY_STORIES_FEED,
  PAGE_VIEW_ACTION,
  RPI_TAG_SELECTION,
} from "./trackingConstants";

export const postPageImpressionEvent = (pageData, contentType, locale = "en", site_host = "") => {
  const secondaryArgs = {
    prelemBaseEndpoint: {
      ...prelemBaseEndpointObj(site_host),
      language: locale,
    },
  };
  const template = {
    action: PAGE_VIEW_ACTION.eventName,
    content_type: contentType,
    tags: getPipedData(pageData?.PageSettings?.PageTags || pageData?.tags),
    content_status: CONTENT_STATUS.FREE,
    author:
      pageData?.Page_createdby ||
      pageData?.developed_by ||
      pageData?.Page_CreatedBy ||
      pageData?.page_createdby,
    ...getdefaultEventObj(
      pageData?.Title || pageData?.title,
      secondaryArgs,
      pageData?.latest_articles || pageData?.latest_courses || pageData?.LatestVods,
    ),
  };

  const eventDetail = {
    ...PAGE_VIEW_ACTION,
    stateValue: "1",
    eventDetails: `${contentType?.toLowerCase()}_${IMPRESSION}`,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template);
};

export const trackRPITagSelection = (data, secondaryArgs) => {
  const { container_type, tags } = data;
  const template = {
    action: BUTTON_CLICK,
    button_name: MY_STORIES_FEED,
    container_type: container_type,
    my_story_preferences: getPipedData(tags),
    destination_url: "",
    ...getdefaultEventObj(container_type, secondaryArgs),
  };

  const eventDetail = {
    ...RPI_TAG_SELECTION,
    stateValue: "1",
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail, secondaryArgs, template); // Track the event
};
