import getConfig from "next/config";

const { publicRuntimeConfig = {} } = getConfig() || {};
export const RpiDefaultConfig = {
  debug: false,
  clientId: publicRuntimeConfig?.NEXT_RPI_CLIENT_ID,
  serverUrl: `${publicRuntimeConfig?.NEXT_RPI_API_URL}/InteractionRealtimeAPI`,
  enableClickTracking: false,
  enablePageVisitTracking: false,
  visitorCookieDuration: 365,
  realtimeCookieDomain: "",
  realtimeCookieSameSite: "",
  serverCookieEnabled: false,
  enableUrlParameterCaching: false,
  urlParameterCachingOverrides: ["urlParam1", "urlParam2"],
  requestGeolocation: true,
  geolocationCookieDuration: 5,
  enableCrossDomainTracking: false,
  trackingDomains: [],
  realtimeListParameters: ["param1", "param2"],
  visitorProfileMergeMode: 1,
  realtimeContextSearchMode: 0,
  enableDoNotTrack: false,
  isPreview: false,
};
export const RpiLensConfig = {
  Lenses: [
    {
      type: "path",
      urlPattern: `${globalThis?.location?.host}/en/*`,
      // "urlPattern": `${publicRuntimeConfig?.NEXT_PUBLISH_APP_URL}/en/*`,
      pathParts: [
        {
          index: 2,
          realtimeParameter: {
            name: "PAGE_TYPE",
            isListParam: false,
            trackLastViewed: true,
            lastViewedParameterName: "LAST_VIEWED_PAGE_TYPE",
          },
        },
        {
          index: 3,
          realtimeParameter: {
            name: "DETAILS",
            isListParam: false,
            trackLastViewed: true,
            lastViewedParameterName: "LAST_VIEWED_DETAILS",
          },
        },
      ],
    },
  ],
};
