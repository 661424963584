import getConfig from "next/config";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useState } from "react";
import { convertLowerCase, objOthersHandle, stringShort } from "../../utils/helperFunctions";
import { getUserId } from "../../utils/tracking/helper";
import { RpiDefaultConfig, RpiLensConfig } from "../RpiConfig/RpiConfig";

const { publicRuntimeConfig = {} } = getConfig() || {};

type PageHeadProps = {
  pageData: any;
  favIcon?: string;
};
export const PageHead = (props: PageHeadProps) => {
  const { pageData = {}, favIcon = "%PUBLIC_URL%/favicon.ico" } = props;
  const objOthers = objOthersHandle(pageData);
  const { noIndex = "" } = objOthers;
  const seoEnabled = pageData?.SeoEnable !== undefined ? pageData?.SeoEnable : pageData?.seo_enable;
  const { settings = {} } = pageData || {};

  const {
    socialog_url = "",
    socialog_title = "",
    seo_keywords = "",
    socialog_image = "",
    SocialOgLocale = "",
    SocialOgSiteName = "",
    socialog_twitter_url = "",
    socialog_description = "",
    socialog_twitter_title = "",
    socialog_twitter_description = "",
  } = settings || {};

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      console.error("Structure Data: Error in parsing JSON");
      return false;
    }
    return true;
  };

  const structuredData =
    pageData?.StructureData !== undefined ? pageData?.StructureData : pageData?.structure_data;
  let arrStructuredData = isJsonString(structuredData) ? JSON.parse(structuredData) : [];

  if (arrStructuredData && Array.isArray(arrStructuredData) && arrStructuredData.length) {
    arrStructuredData = arrStructuredData.filter((item: any) => {
      return item && item !== "{}" && item?.trim() !== "";
    });
  } else {
    arrStructuredData = [JSON.stringify(arrStructuredData)];
  }

  if (pageData?.content_type === "Event" && pageData?.blog_settings)
    arrStructuredData = [...arrStructuredData, JSON.parse(pageData?.blog_settings)];

  const description = stringShort(socialog_description, 40) || "";
  const siteName = SocialOgSiteName ? SocialOgSiteName : "";
  const siteTitle = socialog_title ? socialog_title : "";

  const ogImg = `${publicRuntimeConfig?.NEXT_GCP_URL}/${publicRuntimeConfig?.NEXT_BUCKET_NAME}/${socialog_image}`;
  const faviconUrl =
    publicRuntimeConfig?.NEXT_GCP_URL + "/" + publicRuntimeConfig?.NEXT_BUCKET_NAME + "/" + favIcon;

  const [mainScriptLoaded, setMainScriptLoaded] = useState(false);
  const scriptLoaded = () => {
    setMainScriptLoaded(true);
  };
  useEffect(() => {
    if (mainScriptLoaded) {
      if (typeof window !== "undefined" && typeof window?.rpiWebClient !== "undefined") {
        const VisitorUID = getUserId();
        window.rpiWebClient.init({}, () => {
          globalThis.rpiWebClient = window.rpiWebClient; // Set the global variable
          if (VisitorUID) {
            globalThis.rpiWebClient.visitor.profile.VisitorID = VisitorUID;
            globalThis.rpiWebClient.submitVisitorDetails();
          }
          globalThis.rpiWebClient.visitor.geolocationExpiry = 0; // Reset geolocationExpiry to force a new geolocation request
        });
        // eslint-disable-next-line no-console
        console.log("SDK initialized");
      } else {
        // eslint-disable-next-line no-console
        console.error("SDK initialization failed.");
      }
    }
  }, [mainScriptLoaded]);

  return (
    <>
      {seoEnabled ? (
        <>
          <Head>
            <link rel='canonical' href={socialog_url} />
            {/* Add the favicon link tag */}
            <link rel='icon' type='image/x-icon' href={faviconUrl} />
            <link rel='manifest' href={"/manifest.json"} />
            <title>{siteTitle}</title>
            <meta charSet='UTF-8' />
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1,user-scale=1, maximum-scalable=1,,minimum-scale=1,,maximum-scale=5'
            />
            <meta property='keywords' content={seo_keywords} />

            <meta
              name='google-site-verification'
              content={publicRuntimeConfig?.NEXT_GOOGLE_SEARCH_VERIFICATION}
            />

            {noIndex && <meta name='robots' content='noindex' />}

            {/* Facebook Meta Tags  */}
            <meta property='og:site_name' content={siteName} />

            {/* <meta property="twitter:image:src" content={socialog_image} /> */}
            <meta property='twitter:domain' content={socialog_url} />
            <meta name='theme-color' content='#fff' />

            <meta property='og:type' content='website' />
            <meta property='og:title' content={siteTitle} />
            <meta property='title' content={siteTitle} />

            <meta name='description' property='og:description' content={description} />

            <meta property='og:url' content={socialog_url} />

            <meta property='og:image' content={ogImg} />

            {SocialOgLocale && <meta property='og:locale' content={SocialOgLocale} />}

            {socialog_twitter_title && (
              <meta property='twitter:title' content={socialog_twitter_title} />
            )}

            {socialog_twitter_description && (
              <meta property='twitter:description' content={socialog_twitter_description} />
            )}

            {socialog_twitter_url && (
              <meta property='twitter:site' content={socialog_twitter_url} />
            )}
          </Head>
          {arrStructuredData?.map((item: any, key: number) => {
            return (
              <Script
                id={`SD${key}`}
                type='application/ld+json'
                dangerouslySetInnerHTML={{ __html: item }}
                key={convertLowerCase(key + "arrStructuredData")}
              />
            );
          })}
        </>
      ) : (
        <Head>
          {/* Add the favicon link tag */}
          <link rel='manifest' href={"/manifest.json"} />
          <link rel='icon' type='image/x-icon' href={faviconUrl || "%PUBLIC_URL%/favicon.ico"} />
          <link rel='canonical' href={socialog_url} />
          <meta charSet='UTF-8' />
          <title>{siteTitle}</title>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1,user-scale=1, maximum-scalable=1,,minimum-scale=1,,maximum-scale=5'
          />
        </Head>
      )}
      <Script id='rpiWebClientDefaults'>
        {`
        const rpiWebClientDefaults = ${JSON.stringify(RpiDefaultConfig)};
        const rpiWebClientLensConfig = ${JSON.stringify(RpiLensConfig)};
        `}
      </Script>
      <Script
        src='https://storage.googleapis.com/cropped_image_public/machine_assets/rpiWebClient/rpiWebClient-latest.js'
        type='text/javascript'
        strategy='afterInteractive' // Runs after the page becomes interactive
        onLoad={scriptLoaded}
      />
    </>
  );
};
