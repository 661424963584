import getConfig from "next/config";
import { getCustomEventSchemaList, getRpiInstance, getUserId } from "./helper";
import {
  ANONYMOUSDEVICE,
  ANONYMOUSVISITORID,
  IMPRESSION,
  PAGE_VIEW_ACTION,
} from "./trackingConstants";

const { publicRuntimeConfig = {} } = getConfig() || {};

export const trackImpression = (eventDetail, secondaryArgs, template) => {
  if (
    getRpiInstance() &&
    publicRuntimeConfig.NEXT_ANALYTIC_PROVIDERS?.split(",")?.includes("RPI")
  ) {
    globalThis.rpiWebClient.pushWebEvent(
      eventDetail?.eventName, // Event name
      eventDetail.stateValue, // Quantity or other relevant parameter
      eventDetail?.extraParam, // Event description
      eventDetail?.contentId, // Optional content ID or other identifier
      eventDetail?.data, // Pass the event data object
    );
    getCustomEventSchemaList(eventDetail, secondaryArgs, template);
  } else if (publicRuntimeConfig.NEXT_ANALYTIC_PROVIDERS?.split(",")?.includes("RPI")) {
    window.addEventListener("rpiWebClientLoad", function () {
      globalThis.rpiWebClient.pushWebEvent(
        eventDetail?.eventName, // Event name
        eventDetail.stateValue, // Quantity or other relevant parameter
        eventDetail?.extraParam, // Event description
        eventDetail?.contentId, // Optional content ID or other identifier
        eventDetail?.data, // Pass the event data object
      );
    });
    getCustomEventSchemaList(eventDetail, secondaryArgs, template);
  }
};

export const trackImpressionAPI = (data, contentType) => {
  const url = `${publicRuntimeConfig?.NEXT_RPI_API_URL}/InteractionRealtimeAPI/api/Events`;
  const headers = {
    RPIAuthKey: publicRuntimeConfig?.NEXT_RPI_AUTH_KEY,
    "Content-Type": "application/json",
  };
  const storedUserId = getUserId();
  const storedVisitorId = localStorage.getItem("VisitorID");
  const updatedData = {
    EventName: PAGE_VIEW_ACTION.eventName,
    EventDetail: `${contentType?.toLowerCase()}_${IMPRESSION}`,
    MetricValue: 0,
    ClientID: publicRuntimeConfig?.NEXT_RPI_CLIENT_ID,
    Metadata: data,
    PagePublishedID: 0,
    RequestURL: window?.location?.href,
    VisitorID: storedUserId || storedVisitorId || ANONYMOUSVISITORID,
    DeviceID: ANONYMOUSDEVICE,
  };
  if (publicRuntimeConfig.NEXT_ANALYTIC_PROVIDERS?.split(",")?.includes("RPI"))
    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(updatedData),
    })
      .then((response) => {
        if (response.ok) {
          // eslint-disable-next-line no-console
          console.log("Event addeed in the RPI");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // throw error;
      });
};
